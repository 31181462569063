import { StatsCardViewModel } from '../../models/StatsCardViewModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nwa-statscard',
  templateUrl: './statscard.component.html',
  styleUrls: ['./statscard.component.less']
})
export class StatscardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() value: number;
  @Input() unit: string;
  @Input() icon: string;
  @Input() comparison: boolean;
  @Input() backgroundColor: string;

  constructor() { }

  ngOnInit(): void {
  }

}
