<div class="solar">
    <div class="row mb-3">
        <div class="col">
            <h5>Solar-Status</h5>
            <!-- <span class="subtitle">Stand {{data.time | date: 'dd.MM.YY HH:mm:ss'}} - aktualisiert alle 10s</span> -->
        </div>
    </div>
    @if(data) {
        <div class="solar-info">
            <div class="row mt-3">
                <div class="col-xl-3">
                    <nwa-statscard title="Leistung" subtitle="live" [value]="getData(15) | unit: 2" unit="kWh" icon="home-outline" backgroundColor="#B3D4FF"></nwa-statscard>
                </div>
            </div>
        </div>
    }
</div>
