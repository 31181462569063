<nb-card style="overflow: hidden;">
    <nb-card-body class="d-flex flex-column" [style.backgroundColor]="backgroundColor">
        <div class="mb-auto title-header">
            <span class="title">{{title}}</span>
            <span class="subtitle">{{subtitle}}</span>
        </div>

        <!-- Standardwert -->
        <ng-container *ngIf="!comparison">
            <div class="align-self-baseline stats">
                <span class="stats-number">{{value}}</span><br>
                <span class="unit">{{unit}}</span>
            </div>
        </ng-container>
        <!-- Vergleich -->
        <ng-container *ngIf="comparison">
            <div class="align-self-baseline stats">
                <span class="stats-number">{{statsValue}}</span>
                <span class="unit ms-1">{{unit}}</span><br>
                <div class="comparison">
                    <nb-progress-bar [value]="50"></nb-progress-bar>
                    <span class="comparison-text">51.2 m³ mehr als im Oktober</span>
                </div>
            </div>
        </ng-container>

        <div class="icon">
            <nb-icon [icon]="icon">
            </nb-icon>
        </div>
    </nb-card-body>
</nb-card>