import { Component } from '@angular/core';
import { ActualSolarValue } from '@root/app/models/ActualSolarValue';
import { SolarValue } from '@root/app/models/SolarValue';
import { SolarService } from '@root/app/services/solar.service';
import { da } from 'date-fns/locale';

@Component({
  selector: 'nwa-solar',
  templateUrl: './solar.component.html',
  styleUrl: './solar.component.less'
})
export class SolarComponent {
  interval;
  data: ActualSolarValue[];

  constructor(
    private solarService: SolarService
  ) {
    this.updateData();
    this.startInterval();
  }

  private startInterval() {
    let me = this;

    me.interval = setInterval(() => {
      this.updateData();
    }, 10000);
  }

  private updateData() {
    this.solarService.getLastSolarValue().subscribe(val => {
      this.data = val;
    });
  }

  public getData(confId: number) {
    return this.solarService.getActualSolarValueByConfId(this.data, confId).dataValue;
  }
}
