import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActualSolarValue } from '@root/app/models/ActualSolarValue';
import { SolarValue } from '@root/app/models/SolarValue';

@Injectable({
  providedIn: 'root'
})
export class SolarService {

  constructor(private http: HttpClient) { }

  public getLastSolarValue() {
    return this.http.get<ActualSolarValue[]>('/api/solar');
  }

  public getActualSolarValueByConfId(solarValues: ActualSolarValue[], confId: number) : ActualSolarValue {
    return solarValues.find(x => x.configurationId = confId);
  }
}
