import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from './../../../services/permissions.service';
import { PageService } from './../../../services/page.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import {
  NbIconLibraries,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbMenuModule,
  NbMenuBag,
} from '@nebular/theme';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
} from 'ng-apexcharts';
import { map, filter, scan, switchMap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { UserService } from 'src/app/services/user.service';
import { SidebarService } from '@root/app/services/sidebar.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.less'],
  providers: [NbMenuService],
})
export class PageLayoutComponent implements OnInit {
  title = 'nwamerbach-dashboard';
  user;
  menuSubscription;
  pageMenuItems = [];
  conItems = [
    {
      title: 'Anonymer Modus',
      icon: { icon: 'fa-ghost', pack: 'fa', target: '' },
    },
    {
      title: 'Passwortlose Anmeldung',
      icon: { icon: 'fa-fingerprint', pack: 'fa', target: '' },
    },
    { title: 'Abmelden', icon: 'log-out-outline', target: '' },
  ];
  navigationTag;
  anonymousModeActive = false;
  aiSideBarOpened = false;
  showAiButton = false;

  constructor(
    private iconLibraries: NbIconLibraries,
    private nbSidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private breakpointService: NbMediaBreakpointsService,
    private themeService: NbThemeService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private pageService: PageService,
    private permissionService: PermissionsService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private sidebarService: SidebarService
  ) {
    this.iconLibraries.registerFontPack('fa', { packClass: 'fa' });
  }

  ngOnInit() {
    this.setAnonymousMenuColor();

    this.handleAutomaticMenuToggle();
    this.handleProfileContextMenu();
    this.setProfileName();
    // this.items = this.authenticationService.getPages();

    this.loadPages();

    this.permissionService.loadPermissions().subscribe(() => {
      this.showAiButton = this.permissionService.hasPermission("function.dashbot");
    });

    this.sidebarService.getAiSiteBarStateEmitter().subscribe((opened) => {
      this.aiSideBarOpened = opened;
    })
  }

  public openAiSidebar(open: boolean) {
    this.aiSideBarOpened = open;
  }

  /**
   * Setzt die Farbe im Kontextmenu vom anonymen Modus, wenn aktiv. Style wird in globaler styles.less festgelegt.
   */
  private setAnonymousMenuColor() {
    if (this.userService.isAnonymousMode()) {
      this.conItems[0].target = 'ghost';
      this.anonymousModeActive = true;
    }
  }

  loadPages() {
    // fix bug: double menu items -> UUID
    this.navigationTag = 'main-navigation-' + uuid.v4();

    this.pageService.getPages().subscribe((pages) => {
      this.menuService.addItems(pages as NbMenuItem[], this.navigationTag);

      // Entferne Selektion des Items im jeweils anderen Menü, wenn ein Item aus dem anderen Menü ausgewählt wird:
      // Bei Auswahl von Info -> Deselektiere Auswahl bei den Seiten
      this.menuService.onItemClick().subscribe((x) => {
        if (x.tag != this.navigationTag) {
          this.menuService
            .getSelectedItem(this.navigationTag)
            .subscribe((selectedItem) => {
              if (selectedItem && selectedItem.item) {
                selectedItem.item.selected = false;
              }
            });
        } else {
          this.menuService
            .getSelectedItem('bottom-menu')
            .subscribe((selectedItem) => {
              if (selectedItem && selectedItem.item) {
                selectedItem.item.selected = false;
              }
            });
        }
      });

      this.router.events.subscribe((val) => {
        this.selectPageWithQueryParam();
      });

      this.menuService.onItemSelect().subscribe((x) => {
        this.selectPageWithQueryParam();
      });
    });
  }

  private selectPageWithQueryParam() {
    for (const page of this.pageMenuItems) {
      let sameParams = this.hasSameQueryParams(page);

      if (sameParams) {
        // unselect all unrelevant pages
        this.pageMenuItems.forEach((item) => {
          item.selected = false;
        });

        // select page with query
        page.selected = true;
        return;
      }
    }
  }

  private hasSameQueryParams(page: NbMenuItem): boolean {
    let params = this.route.snapshot.queryParams;

    if (this.router.url.split('?')[0] != page.link) {
      return false;
    }

    if (
      Object.entries(params).length == 0 ||
      Object.entries(page.queryParams).length == 0
    ) {
      return false;
    }

    for (const [param, value] of Object.entries(params)) {
      let pageParam = page.queryParams[param];
      if (pageParam != value) {
        return false;
      }
    }

    return true;
  }

  ngOnDestroy() {
    this.menuSubscription.unsubscribe();
    this.pageMenuItems = [];
  }

  toggleSidebar() {
    this.nbSidebarService.toggle(false, 'sidebar');
  }
  /**
   * Schaltet automatisch den Menudrawer bei mobilen Größen um, wenn ein Menuitem geklickt wird
   */
  handleAutomaticMenuToggle() {
    const { md } = this.breakpointService.getBreakpointsMap();
    this.menuService
      .onItemClick()
      .pipe(
        switchMap(() => this.themeService.onMediaQueryChange()),
        map(([, currentBreakpoint]) => currentBreakpoint.width < md),
        filter((shouldCollapse) => shouldCollapse)
      )
      .subscribe(() => this.nbSidebarService.collapse('sidebar'));
  }

  handleProfileContextMenu() {
    this.menuSubscription = this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'profile-context-menu'),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        switch (title) {
          case 'Abmelden':
            this.authenticationService.logout();
            this.toastr.info('Du wurdest ausgeloggt!');
            break;
          case 'Anonymer Modus':
            this.userService.changeAnonymousMode().subscribe(() => {
              this.toastr.info('Bitte neu anmelden!');
            });
            this.authenticationService.logout();
            break;
          case 'Passwortlose Anmeldung':
            this.router.navigate(['/passwortlos']);
            break;
        }
      });
  }

  setProfileName() {
    this.user = this.authenticationService.getUserInformation();
  }

  bottomMenuItems = [
    {
      title: 'Information',
      icon: 'info-outline',
      link: '/info',
    },
  ];
}
